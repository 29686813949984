@use 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@use '@angular/material' as mat;
@use 'bootstrap/scss/bootstrap';
@use 'ngx-toastr/toastr';
@use '@videogular/ngx-videogular/fonts/videogular';
/* Importing Bootstrap SCSS file. */
$primary: mat.define-palette(mat.$deep-purple-palette, 700);
$accent: mat.define-palette(mat.$deep-purple-palette, 700);
$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
  ),
));
@include mat.all-component-themes($theme);
@include mat.core();


@import url("@angular/material/prebuilt-themes/deeppurple-amber.css");
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
// @import '../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';
@import url("https://fonts.googleapis.com/css?family=Jost:100,200,300,400,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Helvetica:100,200,300,400,600,700,800,900");
// @import "@coreui/angular/scss/coreui";
@import url('https://fonts.cdnfonts.com/css/satoshi'); 
@font-face {
  font-family: lobster;
  src: url(./assets/fonts/Lobster-Regular.ttf) format("opentype");
}

// :not(.mat-button-font-1-5rem) {
//   font-size: 15px;
//   font-family: 'Roboto', sans-serif;
// }
// @media (min-width: 768px) {
//   :not(.mat-button-font-1-5rem) {
//     font-size: 16px;
//     font-family: 'Roboto', sans-serif;
//   }
// }

* :not(.mat-icon){
  font-family: Satoshi !important;
}

.card-section .card {
  width: 100%;
  border-radius: 0px !important;
}

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: 'Manrope', sans-serif;
  font-weight: 800;
}

a {
  color: #0D1B48;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a.link{
  text-decoration: underline;
  letter-spacing: 0.15px;
  color: #008CBE;
}

@media (min-width: 768px){
  .section {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
}
}


/* ======== Colors =========== */

.bg-primary-color {
  background-color: #0D1B48 !important;
}
.bg-orange-color{
  background-color: #F7941D;
}
.bg-purple-color{
  background-color: #36075b;
}
.text-white{
  color: #ffffff;
}
.text-blue{
  color: #0D1B48 !important;
}
.text-purple{
  color: #36075b !important;
}

/* ======== button ============ */
.btn.btn-lg{
  padding: .8rem .75rem;
}
.btn.btn-sm{
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.5rem .375rem 1.5rem;
  font-weight: 600;
  font-size: 14px;
}
.btn-orange{
  color: #ffffff;
  background-color: #F7941D;
  border-color: #F7941D;
}
.btn-purple{
  color: #ffffff;
background-color: #36075b;
border-color: #24033e;
}
.btn-orange:hover {
  color: #ffffff;
  background-color: #da8319;
  border-color: #da8319;
}
.btn-search{
  height: calc(2.25rem + 2px);
  padding: 0.25rem 0.75rem 0.25rem .75rem;
  background-color: #008CBE;
  border-color: #008CBE;
}
.btn-outline-white {
  color: #ffffff;
  background-color: transparent;
  background-image: none;
  border-color: #ffffff;
}
.btn-outline-orange {
  color: #F7941D;
  background-color: transparent;
  background-image: none;
  border-color: #F7941D;
}
.btn-outline-white:hover {
  color: #222222;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn.btn-primary-round {
  border-radius: 50px;
  padding: 8px 20px;
  border-color: #0d1b48;
}
.btn-light-blue{
  background-color: #008CBE;
  border-color: #008CBE;
  color: #ffffff;
}

/* ========= Typography ========= */
h1.main-title {
  font-weight: 800;
}
h2.main-title {
  font-weight: 800;
}
.h3--title{
  font-size: 1.75rem;
}

@media (max-width: 768px) { 
  h1.main-title {
    font-weight: 800;
    font-size: 30px;
}
.h3--title{
  font-size: 1.50rem;
}
.copyright a {
  display: inline-block;
  margin-bottom: .5rem;
  margin-left: 0px;
}
}

@media (max-width: 576px){
  .h3--title {
      font-size: 1.30rem;
  }
}

/* ========= Navigation ========== */

.nav-link {
  text-transform: uppercase;
  font-weight: 600;
}
.nav-form-input{
  /* min-width: 280px; */
  border-right: transparent;
}
.nav-form-select {
  color: #000000;
  font-weight: 600;
}

.navbar-nav .nav-item a.nav-link:hover,
.navbar-nav .nav-item a.nav-link.active {
 border-bottom: 3px solid #008CBE;
}
.bootstrap-select>.dropdown-toggle{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  padding-left: 1rem;
  padding-right: 1rem;
}

.nav-link-social svg{
  height: 16px;
  width: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.navbar{
  padding:.5rem 0 ;
}

@media (min-width: 992px){
.navbar-nav .nav-link {
    margin-right: 1rem;
}
.navbar-nav  .nav-item .nav-link-social {
    margin-right: 1rem;
}
.navbar-nav  .nav-item:last-child .nav-link-social {
  margin-right: 0rem;
}
}

/* FAQ */

.faq-item-heading:before {
  content: '+';
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  margin-right: 1rem;
  background-color: #f7941d;
  font-weight: 700;
  color: #fff;
  -webkit-transition: inherit;
  transition: inherit;
}

.faq-item+.faq-item {
  border-top: 1px solid rgba(0,0,0,.1);
}
.faq-item {
  padding: 1rem 0;
}
.faq-item-content-inner {
  padding-top: 1rem;
}
a.card:focus, a.card:hover, a.faq-item-heading {
  text-decoration: none;
}
a.faq-item-heading{
  font-weight: 700;
}

/* Login */

.box-content,
.logo-shadow{
  background: #ffffff 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 20px 50px #008CBE29;
border-radius: 10px;
opacity: 1;
}
/* .box-content{
  z-index: 998;
} */
.signup.box-content{
  box-shadow: none;
}
.signup.box-content ul li a.nav-link.active,
.box-content ul li a.nav-link.active{
  background-color: #0D1B48;
}
.logo-shadow{
    padding: 20px;
    display: inline-block;
    border-radius: 80px;
    z-index: 900;
    margin-bottom: -55px;
    position: sticky;
    top: -80px;
    text-align: center;
    align-items: center;
}
main.business-signin {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.form-signin {
  width: 100%;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
hr.divider{
  border-color: #008CBE30;
}

p.or{
  margin-top: -28px;
}
p.or span{
  background-color: #fff;
  z-index: 1;
  padding: 0px 8px;
}

.btn-google{
  color: #0D1B48;
  background-color: #fff;
  border-color: #E6EAF1;
  position: relative;
}
.btn-google:hover {  
  color: #0D1B48;
  background-color: #E6EAF1;
  border-color: #E6EAF1
}

.btn-fb{
  color: #ffffff;
  background-color: #1878F2;
  border-color: #1878F2;
  position: relative;
}
.btn-fb:hover {
  color: #ffffff;
  background-color: #196cd8;
  border-color: #196cd8;
}
.btn-fb span, .btn-google span{
  position: absolute;
  left: 10px;
}
.login-sm-text, p.login-sm-text a {
  font-size: 14px;
}


/* Trasactions */

.table td, .table th {
  vertical-align: middle;
  border-top: .0625rem solid #f0f3f6;
}
th, td {
  border-radius: 10px;
}
.table {

  width: 100%;
  margin-bottom: 1rem;
  color: #4a5073;
  background-color: transparent;
}
.transactions.card{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: .0625rem solid rgba(243,247,250,.05);
  border-radius: .5rem;
}


.input-group-text {
  display: flex;
  align-items: center;
  padding: .6rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #506690;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: .0625rem solid #eaedf2;
  border-radius: .5rem;
}
#transactions .form-control {
  display: block;
  height: calc(1.5em + 1.4rem);
  padding: .6rem .75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #4a5073;
  background-color: #fff;
  background-clip: padding-box;
  border: .0625rem solid #eaedf2;
  border-radius: .5rem;
  box-shadow: 0 4px 30px rgb(0 0 0 / 5%);
  transition: all .3s ease-in-out;
}
.input-group-prepend {
  display: flex;
}
.input-group .form-control:not(:first-child) {
  border-left: 0;
  padding-left: 0;
}
#transactions .input-group> .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#transactions .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control, .input-group>.form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}




.table td, .table th {
  padding: 1rem;
  vertical-align: top;
  border-top: .0625rem solid #f0f3f6;
  border: 1px solid black;
  border-radius: 10px;
}
.card-footer:last-child {
  border-radius: 0 0 calc(.5rem - .0625rem) calc(.5rem - .0625rem);
}
.card-footer {
  padding: 1.25rem 1.5rem;
  background-color: #f3f7fa;
  border-top: .0625rem solid rgba(243,247,250,.05);
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0D1B48;
  border-color:  #0D1B48;
}
.page-link:hover {
  z-index: 2;
  color: #0D1B48;
  text-decoration: none;
  background-color: #f0f3f6;
  border-color: #f0f3f6;
}
.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -.0625rem;
  line-height: 1.25;
  color: #506690;
  background-color: #fff;
  border: .0625rem solid #eaedf2;
}
.fa-ellipsis-h:before {
  content: "\f141";
}
#transactions .dropdown-toggle::after{
  display: none;
}

/* Privacy Policy */
#privacy .card-header:first-child {
  border-radius: .75rem .75rem 0 0;
}
#privacy {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
#privacy .card {
  border-width: 0;
  box-shadow: 0 12px 15px rgb(140 152 164 / 10%);
  border-radius: .75rem;
}

/*Terms*/
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky-top + .content {
  padding-top: 150px;
}

ul.scroll-nav-terms a{
  font-size: 14px;
}
ul.scroll-nav-terms li a{
  font-size: 14px;
  font-weight:normal;
  padding: 0px;
  padding-bottom: .5rem;
}
.scroll-nav-terms-ol li a{
  padding: 0px;
}
.scroll-nav-terms-ol .nav-item a.nav-link:hover{
  border: none;
}

/*About*/
main.about section{
  padding: 6rem 0;
}

.process-vertical {
  padding: 0;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.process-circle:empty.bg-primary {
  background: rgba(13, 27, 72,0.95) !important;
}
.process-vertical li {
  display: flex;
  align-items: center;
}
.process-circle:empty:after {
  content: '';
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
}

.process-circle {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #fff;
  font-size: 1.125rem;
}
.process-vertical li .process-circle {
  margin-right: 1.5rem;
}
.process-circle:empty.bg-primary:after {
  background-color: #008BBE;
}


.process-vertical li .process-circle {
  margin-left: -1.5rem;
}

@media (min-width: 768px){

  .process-vertical li:not(:last-child) {
    position: relative;
    margin-bottom: 1.5rem;
}

  .process-vertical li:not(:last-child) {
    padding-bottom: 4.5rem;
    margin-bottom: 0;
}

.process-vertical li {
  width: 50%;
  margin-left: 50%;
}

.process-vertical li:nth-child(even) {
  flex-direction: row-reverse;
  text-align: right;
  margin-left: 0;
  margin-right: 50%;
}
.process-vertical li:not(:last-child):after {
  content: '';
  display: block;
  width: 1px;
  height: 3rem;
  background: #dee2e6;
  position: absolute;
  top: 4.125rem;
}
.process-vertical li:nth-child(even) .process-circle {
  margin-right: -1.5rem;
  margin-left: 1.5rem;
}
}
.avatar {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.avatar.avatar-xlg {
  width: 7.5rem;
  height: 7.5rem;
}

.badge {
  text-transform: uppercase;
  letter-spacing: .02em;
  position: relative;
  bottom: 1px;
  vertical-align: middle;
}

.badge.badge-top {
  position: absolute;
  top: -.75rem;
  bottom: auto;
}
.card {
  margin-bottom: 1.5rem;
}
@media (min-width: 576px){
  .card.card-lg.card-body {
    padding: 3rem 2rem;
}
}
.counter{
  font-size: 3.5rem;
    font-weight: 600;
  color: #f7941d!important;
}
/* Page ++++ Business ++++ */
/* Request Demo */
#request-demo{
  padding: 25px;
  background: url(./assets/img/request-demo.png);
  background-repeat: no-repeat;
  background-size: auto;
}
.request-demo-close{
  background-color: #F7941D !important;
  position: absolute;
  width: 32px;
  margin-top: -16px;
  height: 32px;
  right: -13px;
  z-index: 950;
  border-radius: 16px;
  opacity: 1;
  color: #fff;
}
.demo-header{
  max-width: 700px;
}
/* ========= Pricing plans ============ */

hr{
  border-color: #707070;
}

.btn-gold {
  background-color: #F8D7B5;
  color: #000000;
}
.btn-gold:hover {
  background-color: #dfc0a2;
  color: #000000;
}
.btn-black {
  background-color: #000000;
  color: #ffffff;
}
.btn-black:hover {
  background-color: #1a1a1a;
  color: #ffffff;
}
.card-section {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.card-one {
  background-color: #ffffff;
}
.card-two {
  background-color: #F2F2F2;
}
.card-three {
  background-color: #F8D7B5;
}
.card-four {
  background-color: #F6F6F6;
}
.circle {
  /* background: transparent; */
  /* background-color: transparent; */
  border-radius: 40px;
  /* color: #000000; */
  /* border-color: #000000; */
  height: 40px;
  font-weight: bold;
  width: 40px;
  display: table;
  margin: 20px auto;
}
.block {
  text-align: center;
  vertical-align: middle;
}
.circle p {
  vertical-align: middle;
  display: table-cell;
}
.circle-row {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.packages {
  background-color: gainsboro;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  padding-top: 15px;
}
.circle-one {
  background-color: #1e0d57;
  color: #ffffff;
}
.circle-two {
  background-color: transparent;
  color: #000000;
  border: 2px solid black;
}
.circle-three {
  background-color: transparent;
  color: #000000;
  border: 2px solid black;
}
.circle-four {
  background-color: transparent;
  color: #000000;
  border: 2px solid black;
}
.circle-five {
  background-color: transparent;
  color: #000000;
  border: 2px solid black;
}
.card-paragraph-div {
    min-height: 160px;
}
.card-req {
  min-height: 340px;
}
.features {
  min-height: 285px;
}
@media (max-width: 1200px) {
  .card-req {
    min-height: 215px;
  }
  }

  @media (max-width: 991px) {
    .card-req {
      min-height: 298px;
    }
    }

    
@media (max-width: 768px) {
  .features {
    min-height: auto;
  }
  .card-paragraph-div {
    min-height: auto;
  }
  .card-req {
    min-height: auto;
  }
  
}


/* ========= Product-Safety ========= */

#product-features figure img {
  border: 1px solid #A8F4FB;
  border-radius: 10px;
}
#product-features h2,#product-features h3,
#product-pricing h2,#product-pricing h3,#product-pricing h4{
  margin-bottom: 20px;
  font-weight: 800;
}
#product-pricing h4{
  margin-bottom: 10px;
}
#product-features article p{
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;
}

#product-pricing h5.card-price{
 color: #1A1A1A;
}
#prduct-pricing div ul li {
  padding-bottom: 6px;
}
.btn-block.btn-black{
  margin-top: 1rem;
}


.circle p {
  vertical-align: middle;
  display: table-cell;
}
.circle-row {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.packages {
  background-color: gainsboro;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  padding-top: 15px;
}
.product-no.circle-one {
  background-color: #1e0d57;
  color: #ffffff;
}
.product-no.circle-two {
  background-color: transparent;
  color: #000000;
  border: 2px solid black;
}
.product-no.circle-three {
  background-color: transparent;
  color: #000000;
  border: 2px solid black;
}
.product-no.circle-four {
  background-color: transparent;
  color: #000000;
  border: 2px solid black;
}
.product-no.circle-five {
  background-color: transparent;
  color: #000000;
  border: 2px solid black;
}
.product-no.circle {
  /* background: transparent; */
  /* background-color: transparent; */
  border-radius: 40px;
  /* color: #000000; */
  /* border-color: #000000; */
  height: 40px;
  font-weight: bold;
  width: 40px;
  display: table;
  margin: 20px auto;
}
.product-output.card-columns a.card {
  min-height: 265px;
  margin-top: 15px;
}

/* End Page ++++ Business ++++ */

/* ++++ Product Page ++++ */
.bg-baby-card {
  background-color: #edf7f7;
  border-radius: 12px;
  /* margin-right: 5px;
  margin-left: 5px; */
}
.ingredient-list {
  background-color: #edf7f7;
  border-radius: 12px;
  /* min-height: 490px; */
}
.badge.ingredient-score{
	position: absolute;
    right: 0;
    padding: 10px;
    margin: 8px;
    border-radius: 20px;
    height: 40px;
    width: 40px;
    vertical-align: middle;
    line-height: 20px;
    color: #fff;
    font-size: 16px !important;
}
.circle {
  /* background: transparent; */
  /* background-color: transparent; */
  border-radius: 40px;
  /* color: #000000; */
  /* border-color: #000000; */
  height: 60px;
  font-weight: bold;
  width: 60px;
  display: table;
  margin: 12px;
}
.big-circle {
  border-radius: 40px;
  height: 80px;
  font-weight: bold;
  width: 80px;
  display: table;
  margin: 20px auto;
}
.block {
  text-align: center;
  vertical-align: middle;
}
.circle p {
  vertical-align: middle;
  display: table-cell;
  font-size: 16px;
  font-weight: 800;
}
.big-circle p {
  vertical-align: middle;
  display: table-cell;
  font-size: 20px;
  font-weight: 800;
}
.small-circle {
  border-radius: 40px;
    height: 40px;
    font-weight: bold;
    width: 40px;
    display: table;
    margin: -30px auto;
}
.small-circle p {
  vertical-align: middle;
  display: table-cell;
}
.circle-one {
  background-color: green;
  color: #ffffff;
}
li {
  border-radius: 4px;
}
.bg-orange {
  background-color: orange;
  color: #ffffff;
}
.bg-green {
  background-color: green;
  color: #ffffff;
}
.bg-red {
  background-color: red;
  color: #ffffff;
}
.card-imgs {
  width: 40%;
  border-radius: calc(0.25rem - 1px);
  margin-top: -86px;
}
.similar-products {
  width: 100%;
  text-align: center;
  margin-bottom: -22px;
  margin-top: 22px;
}
.div1,
.div2 {
  display: inline-block;
}

.ingredient-list h5,
.card-head-title{
 font-weight: 800;
}
.ingredient-list .list-group-item{
  font-weight: 600;
  padding: 1.25rem 1.25rem;
  border: none;
  margin-bottom: 2px;
 }
.overall-score .list-group-item{
  border: none;
  background-color: transparent;
  font-weight: 600;
}
.social-media{
  padding: 0px 12px;
}
/* Progress bar */
.ingredient-concerns {
  height: 25px;
}

.ingredient-concerns .progress-bar{
  border: 1px solid #fff;
  background-color: #42474D;
}

.ingredient-concerns .progress-bar.bg-red{
  background-color: #FF0000 !important;
}

.ingredient-concerns .progress-bar.bg-green{
  background-color: #8AC53F !important;
}

.ingredient-concerns .progress-bar.bg-orange{
  background-color: #F7941D !important;
}



/* End Page ++++ Product Page ++++ */

/* ++++ Search Page ++++ */
.search-toggle{
  background-color: rgb(204 241 255)!important;
    color: #2b4596;
}
.badge {
  display: inline-block;
  padding: .35em .50em;
  font-size: 75%;
}

/* End Page ++++ Search Page ++++ */

/* ingredient-list */
.ingredient-card .card{
  width:300px;
}

@media (min-width: 576px) {
  .card-columns {
      column-count: 2;
  }
}

@media (min-width: 768px) {
  .card-columns {
      column-count: 3;
  }

}

@media (min-width: 992px) {
  .card-columns {
      column-count: 4;
  }
}

@media (min-width: 1200px) {
  .card-columns {
      column-count: 5;
    column-gap: 1rem;
  }  
}
.card-columns{
  margin-top: .5rem;
}
.card-columns a.card{
  min-height: 295px;
  margin-top: 15px;
}
.card-columns a.card:hover{
  box-shadow: 0px 2px 5px 5px #e0e0e0;
  text-decoration: none;
  cursor:pointer;
}


/* ========= Footer ========= */
footer.footer li a.f-link{
  color: #A8F4FB;
}

footer.footer li{
  margin-top: 16px;
}
.copyright a,
.footer ul li a {
  color: #A8F4FB;
  margin-left: 10px;
}
.footer ul li a{
  margin-left:0px;
}
.copyright a,
.footer ul li a{
  position: relative;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.copyright a:after,
.footer ul li a:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: #0D1B48;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.copyright a:after,
.footer ul li a:after {
  background-color: #000000;
}

.copyright a:after,
.footer ul li a:after {
  background-color: #ffffff;
}

.copyright a:hover, .copyright a:focus,
.footer ul li a:hover, .footer ul li a:focus {
  background-color: transparent;
  text-decoration: none;
}

.copyright a:hover:after, .copyright a:focus:after,
.footer ul li a:hover:after, .footer ul li a:focus:after {
  width: 100%;
}

/* Consumer Products*/


.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 40px;
  width: 40px;
  background-size: 100%, 100%;
  border-radius: 50%;
  background-image: none;
  background-color: #fff;
  position: relative;
}

.carousel-control-prev-icon:after
{
  content: "\f104";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: bold;
    text-decoration: inherit;
    color: #0D1B48;
    font-size: 20px;
    padding: 0 0.8em;
    top: 6px;
    right: 0;
    position: absolute;
}

.carousel-control-next-icon:after
{
  content: "\f105";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: bold;
    text-decoration: inherit;
    color: #0D1B48;
    font-size: 20px;
    padding: 0 0.8em;
    top: 6px;
    right: 0;
    position: absolute;
}


.product-btn-link {
  font-weight: 700;
  color: #008CBE;
  font-size: 18px;
}

a.primary-link{
  color: #0D1B48;
}

.product-score.circle {
  width: 40px;
  height: 40px;
}
.ingredient-list figure img.card-imgs {
  margin-top: 5px;
}

    
@media (max-width: 1200px) {

  .ingredient-list {
    background-color: #edf7f7;
    border-radius: 12px;
    min-height: auto;
  }
}

.progress .progress-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: .6s;
  height: 40px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px !important;
  font-size: 20px;
  font-weight: bold;
  border: 2px solid #fff;
}

.progress .progress-bar:last-child {
  border-right: 2px solid #fff;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.progress {
  margin-left: 40px;
  height: 40px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0px !important;
  background-color: #5a5a5a !important;
}

.progress-sec {
  position: relative;
  /* background-color: #fafafa; */
}

.circle--graph {
  height: 80px;
  width: 80px;
  position: absolute;
  left: -20px;
  border-radius: 40px;
  top: -20px;
  box-shadow: 5px 5px 7px #0000004d;
  z-index: 999;
  border: 4px solid #fff;

}

.btn--rounded {
  border-radius: 40px;
  padding-right: 25px;
  padding-left: 25px;
  color: #fff !important;
}

.circle--graph span {
  position: relative;
  margin: 0 auto;
  display: block;
  padding: 10px;
}

.card--ingredient {
  background-color: #F6F7FB;
  padding: 15px;
}

.card--ingredient .card-footer {
  background-color: #F6F7FB;
}

.bg-green {
  background-color: #00aa00 !important;
}

.bg-red {
  background-color: #ef1010 !important;
}

.bg-orange {
  background-color: #ffc600 !important;
}
.bg-grey{
  background-color: #5a5a5a !important;
}
.h3--font p {
  font-size: 20px !important;
}
.t-white{
  color: #fff !important;
}
.circle.bg-grey,
.circle.bg-green,
.circle.bg-red,
.circle.bg-orange {
  margin: 0px;
  margin-right: 12px;
  margin-top: 12px;
}


.box4 .box-content{
  top: 0;
  left: 0;
  text-align: center;
  transform: scale(0);
  transition: all .3s ease 0s;}
.box4:hover .box-content{
  transform:scale(1);
padding-left: 15px;}

.ingredient-list .box-content{
  background-color: transparent;  
  box-shadow: none;
}

.ingredient-list .box-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute !important;
    padding-top: 15px;
    margin-left: 15px;
}

.ingredient-list .box-content {

font-size: 12px;
}
.ingredient-list figure img.card-imgs  {
    width: 40%;
}
a.card-link{
  text-decoration: none;
}

.ingredient--sec .ingredient-list{
    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid #dedede;
}
.scroll-nav-terms  li  a,
.form-signin li a.nav-link{
  text-transform:none;
}
#pills-consumer-tab {
  border: 1px solid #E9EAEE;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
#pills-business-tab{
  border: 1px solid #E9EAEE;
  
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
#pills-business-tab.active,
#pills-consumer-tab.active{
  border: 1px solid #0D1B48;
}

@keyframes animateBar {
  0% {
      transform: translateX(-400%);
  }

  100% {
      transform: translateX(0);
  }
}

/*button */
.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: #3e8e41;
}

#myInput {
  box-sizing: border-box;
  // background-image: url(./assets/scss);
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}

#myInput:focus {outline: 3px solid #ddd;}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}



/* Success syling */

.mat-button.mat-success,
.mat-stroked-button.mat-success {
    color: #155724;
}
.mat-button.mat-success:hover,
.mat-stroked-button.mat-success:hover {
  background-color: #f0fff3;
}
.mat-mdc-raised-button.mat-success,
.mat-flat-button.mat-success,
.mat-fab.mat-success,
.mat-mini-fab.mat-success {
  color: #f0fff3;
  background-color: #155724;
}

.mat-icon-button.mat-success {
  color:#155724;
}

.mat-chip.mat-selected {
  color:#000000;
  background-color: #155724;
}

.mat-chip.mat-unselected {
  color:#ffffff;
  background-color: #cacaca;
}

.mat-chip.mat-rimary {
  color:#ffffff;
  background-color: #36075b;
}
.mat-icon-button.mat-yellow {
  color:#B7A71F;
}
.mat-icon.mat-yellow {
  color:#B7A71F;
}


.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 18px;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 12px;
  border-radius: 10px;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: #36075b;
}
.mat-accent .mat-slider-thumb {
  height: 30px;
  width: 30px;
  background-color: white;
  border: solid 2px gray;
  bottom: -20px;
  right: -20px;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: white;
}

mat-bottom-sheet-container {
  position: fixed;
  bottom: 5%;
  right: 1%;
  width: 10%
}

/* Importing Bootstrap SCSS file. */


.maya-bg-orange{
  background-color: rgb(221, 139, 62);
}

.right-aligned-header > .mat-content {
  justify-content: space-between;
}

.mat-content > mat-panel-title, .mat-content > mat-panel-description {
flex: 0 0 auto;
}

.matInputLabel{
  align-self: center;
}

.divider {
  margin: 16px 0px;
}

// $theme: mat.define-theme((
//   color: (
//     theme-type: dark,
//     primary: mat.$violet-palette,
//   ),
//   typography: (
//     brand-family: 'Jost',
//     bold-weight: 800
//   ),
//   density: (
//     scale: -1
//   )
// ));


.mat-chip .mat-selected{
  background-color: #155724;
  color: black;
}

.flex{
  display: flex;
}

.flex-row-rev{
  flex-direction: row-reverse;
}

.flex-grow{
  flex-grow: 1;
}

.bg-badge{
  background-color: #D25E1F;
  color: white;
}

.carousel-indicators{
  display: none;
}

.mat-mdc-raised-button.mat-primary{
  background-color: #dd7d49;
  padding: 0.1rem 2rem;
  background: #dd7d49;
  color: black;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.4);
}

.font-bold { 
  font-weight: bold;
}

.mat-mdc-raised-button{
  padding: 0.1rem 2rem;
  background: #dd7d49;
  color: black;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.4) !important;
}

.mat-mdc-raised-button.mat-accent{
  background-color: #36075b;
  padding: 0.1rem 2rem;
  background: #36075b;
  color: white;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.4);
}

.mat-mdc-chip.mat-accent{
  background-color: #36075b !important;
  background: #36075b;
  color: white !important;

  .mat-mdc-chip-action-label{
    color: white !important;
  }

}

.mat-button-font-1-5rem{
  font-size: 1.5rem !important;
}

.mat-chip.mat-success{
  background-color: #155724;
  background: #155724;
  color: white;
}

.justify-content-space-around{
  justify-content: space-around;
}

.flex-center{
  display: flex;
  align-items: center;
}

.width-max{
  width: max-content;
}

.border-black{
  border-color: #000000;
}

.border-curved{
  border-radius: 1rem;
}

.g-1{
  gap: 0.1rem;
}

.g-2{
  gap: 0.2rem;
}

.g-3{
  gap: 0.5rem;
}

.g-4{
  gap: 1rem;
}

.g-5{
  gap: 1.5rem;
}


.border-all{
  border: 1px solid black !important;
}

.border-left{
  border-left: 1px solid black;
}

.border-right{
  border-right: 1px solid black;
}

.border-top{
  border-top: 1px solid black;
}

.border-bottom{
  border-bottom: 1px solid black !important;
}


.border-left-none{
  border-left: none !important;
}

.border-right-none{
  border-right: none !important;
}

.border-top-none{
  border-top: none !important;
}

.border-bottom-none{
  border-bottom: none !important;
}

.border-vertical{
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
}

.border-horizontal{
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}

.border-vertical-none {
  border-top: none !important;
  border-bottom: none !important;
}

.border-horizontal-none{
  border-left: none !important;
  border-right: none !important;
}

.border-none{
  border: none !important;
}

.justify-end{
  justify-content: flex-end;
}
.cursor-pointer{
  cursor: pointer;
}

.mat-drawer-inner-container {
  height: fit-content !important;
}

.brand-package-subtitle{
  color: var(--purple, #662F91);
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 148%;
  text-transform: uppercase;
}

.mat-button.mat-primary{
  color: #662F91;
}

.mdc-dialog__title{
  margin: 1rem 0.5rem 0.2rem !important;
}

.mat-mdc-dialog-actions{
  display: flex !important;
  justify-content: flex-end !important;
  padding: 0.5rem 1rem !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: none;
}

.mat-mdc-chip{
  .mat-mdc-chip-action-label{
    color: inherit !important;
  }

  .mdc-evolution-chip__text-label{
    color: inherit;
  }

}

.specialized-chip{
  color: var(--white, #FFF) !important;
  font-family: Satoshi;
  font-size: 14.726px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  background: #C76A26 !important;
}

.verified-chip{
  color: var(--white, #FFF) !important;
  font-family: Satoshi;
  font-size: 14.726px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  background: #4B8E4D !important;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}